<template>
  <div class="w-100">
    <canvas :id="id" />
  </div>
</template>

<script>
import Chart from 'chart.js'

export default {
  name: 'Chart',
  props: {
    id: {
      type: String,
      default: ''
    },
    chartType: {
      type: String,
      default: ''
    },
    chartData: {
      type: Object,
      default: () => ({})
    },
    chartOptions: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      chart: null
    }
  },
  watch: {
    chartData: {
      deep: true,
      handler () {
        this.createChart(this.chartType, this.chartData, this.chartOptions)
      }
    }
  },
  mounted () {
    this.createChart(this.chartType, this.chartData, this.chartOptions)
  },
  methods: {
    createChart (chartType, chartData, chartOptions) {
      const ctx = document.getElementById(this.id).getContext('2d')
      this.chart = new Chart(ctx, {
        type: chartType,
        data: chartData,
        options: chartOptions
      })
    }
  }
}
</script>

<style scoped>

</style>
